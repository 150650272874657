import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import gspService, { GspService } from '@/shared/model/gspService';
// import { AUTHORITIES } from '@/shared/store/modules/auth';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';

const name = 'gspService-edit-dialog';
const logger = new Logger(name);
const gspServiceManagement = namespace('gspService');
const gspRoleModule = namespace('gspRole');

@Component({
  name: name,
  components: {
    D4yEdit,
  },
})
export default class GspServiceEditDialog extends Vue {
  @gspServiceManagement.Action('getGspService') private actionGetGspService!: any;
  @gspServiceManagement.Action('updateGspService')
  private actionUpdateGspService!: any;
  @gspServiceManagement.Getter('getGspServiceRoles')
  private roles!: any;

  @gspRoleModule.Action('getGspRoleContactTypes') private actionGetGspRoleContactTypes!: any;
  @gspRoleModule.Getter('getGspRoleContactTypes')
  private gspRoleContactTypes!: any;

  selectedContactTypes = [];
  roleContactTypes = [];



  @Prop()
  serviceId: any;

  get contactTypes() {
    let result = this.selectedContactTypes;
    return result;
 }

  async mounted() {
    this.roleContactTypes = this.gspRoleContactTypes;
    const gspServiceId = this.serviceId; //this.$route.params['gspServiceId'];
    if (gspServiceId != 0) {
      this.getGspService();
    } else {
      this.gspService = gspService.parse({});
    }
  }
  getGspService() {
    const gspServiceId = this.serviceId;//this.$route.params['gspServiceId'];
    this.actionGetGspService(gspServiceId).then((response: GspService) => {
      this.gspService = Object.assign({}, response);
      this.selectedContactTypes = this.gspService.contact_types?.map((x:any) => x.id);

      this.titleInfo = this.gspService.name;
    });
  }
  private gspService: GspService = gspService.parse({});

  @Ref('observer-add-edit-gspService-form') private observerForm!: any;

  activeTab = 0;
  isLoading = false;
  titleInfo = 'empty';

  get isEmptyGspService() {
    return this.gspService.id == 0;
  }

  get image() {
    return {};
    return {
      src: 'img_gspService.png',
      header: '',
      width: 350,
      height: 200,
    };
  }

  onChangeContactType(value: any) {
    console.log('onChangeContactType value :>> ', value);
    this.selectedContactTypes = value;
    this.gspService.contactTypeIds = value;
  }

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    await this.actionUpdateGspService(this.gspService).then((resp: GspService) => {
      this.isLoading = false;
      // this.getGspService();
    });
  }

  onClickedClose() {
    // this.$router.push({
    //   name: ROUTES.gspServices,
    // });
    this.$emit('close-dialog');
  }
}
