var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-card',[_c('validation-observer',{ref:"observer-add-edit-gspModule-form",scopedSlots:_vm._u([{key:"default",fn:function(v){return [_c('v-form',{attrs:{"id":"editForm","data-vv-scope":"editForm"}},[_c('v-card-text',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab',[_vm._v(" "+_vm._s(_vm.$t('data'))+" ")])],1),_c('v-tabs-items',{staticClass:"mt-3",model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('v-row',{staticClass:"pt-3",attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('name'),"rules":"max:64|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('name'),"name":"name","outlined":"","error-messages":errors[0]},model:{value:(_vm.gspModule.name),callback:function ($$v) {_vm.$set(_vm.gspModule, "name", $$v)},expression:"gspModule.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('azure_group'),"rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('azure_group'),"name":"azure_group","outlined":"","error-messages":errors[0]},model:{value:(_vm.gspModule.entra_Group),callback:function ($$v) {_vm.$set(_vm.gspModule, "entra_Group", $$v)},expression:"gspModule.entra_Group"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('service'),"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('d4y-select',{attrs:{"label":_vm.$t('service'),"name":"service","items":_vm.services,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.id; },"error-messages":errors[0]},on:{"change":_vm.fillCommonTextIfPresent},model:{value:(_vm.gspModule.serviceId),callback:function ($$v) {_vm.$set(_vm.gspModule, "serviceId", $$v)},expression:"gspModule.serviceId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-checkbox',{staticClass:"mt-1 d-inline-flex mr-5",attrs:{"input-value":_vm.gspModule.isCommon,"label":_vm.$t('is_common'),"color":"orange-btn","hide-details":""},model:{value:(_vm.gspModule.isCommon),callback:function ($$v) {_vm.$set(_vm.gspModule, "isCommon", $$v)},expression:"gspModule.isCommon"}})],1),(_vm.gspModule.isCommon)?_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","label":_vm.$t('common_text'),"name":"common_text","outlined":""},model:{value:(_vm.gspModule.short_common_text),callback:function ($$v) {_vm.$set(_vm.gspModule, "short_common_text", $$v)},expression:"gspModule.short_common_text"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":_vm.$t('module_contact_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('d4y-autocomplete',{attrs:{"value":_vm.contactTypes,"label":_vm.$t('module_contact_type'),"name":"moduleContactType","items":_vm.roleContactTypes,"item-text":function (item) { return item.name; },"item-value":function (item) { return item.id; },"multiple":"","hide-details":!errors[0],"error-messages":errors[0]},on:{"change":_vm.onChangeContactType}})]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0","color":"error"},on:{"click":_vm.onClickedClose}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"elevation":"0","dark":"","color":"primary","disabled":_vm.isLoading,"loading":_vm.isLoading},on:{"click":_vm.onClickedUpdate}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }