import { ROUTES } from '@/router/routesEnum';
import tblUpdateDocument, { TblUpdateDocument } from '@/shared/model/tblUpdateDocument';
import { CompletedInfo } from '@/shared/model/tblUpdateSupplier';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import DateUtils from '@/shared/utils/DateUtils';
import { Contact } from '@/shared/model/contact';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import searchData from '@/shared/model/smallPayloadModels/searchData';
import { TblUpdateCondition } from '@/shared/model/tblUpdateCondition';

const name = 'tblUpdateDocuments-view';
const contactModule = namespace('contact');
const tblUpdateDocumentModule = namespace('tblUpdateDocument');
const tblUpdateSupplierModule = namespace('tblUpdateSupplier');
const tblUpdateConditionModule = namespace('tblUpdateCondition');
const authModule = namespace('auth');

@Component({ name: name, components: { D4yTable, DateField } })
export default class TblUpdateDocumentsComponent extends Vue {
  @tblUpdateDocumentModule.Action('getTblUpdateDocuments')
  private actionGettblUpdateDocuments!: any;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocuments')
  private tblUpdateDocuments!: OdataItems<TblUpdateDocument>;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentsSearchParams')
  private tblUpdateDocumentsSearchParams!: SearchParams;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentsIsLoading')
  private tblUpdateDocumentsIsLoading!: boolean;
  @tblUpdateDocumentModule.Getter('getTblUpdateDocumentrsTotal')
  private tblUpdateDocumentrsTotal!: number;
  @tblUpdateDocumentModule.Action('deleteTblUpdateDocument')
  private actionDeleteTblUpdateDocument!: any;
  @tblUpdateDocumentModule.Action('getAddDocumentCompleted')
  private actionAddDocumentCompleted!: any;
  @tblUpdateConditionModule.Action('updateDashboardDate')
  private actionUpdateConditionDashboardDate!: any;
  @tblUpdateSupplierModule.Action('updateDashboardDate')
  private actionUpdateDashboardDate!: any;
  @tblUpdateDocumentModule.Action('updateDashboardDate')
  private actionUpdateDocumentDashboardDate!: any;

  @tblUpdateDocumentModule.Getter('getDashboardDate')
  private dashboardDate!: any;
  @contactModule.Getter('getContactForLoggedInUser')
  private contactForLoggedInUser!: Contact;

  @tblUpdateDocumentModule.Action('updateDashboardDocumentsStatus')
  private actionGetDashboardDocumentsStatus!: any;
  @tblUpdateDocumentModule.Getter('getDashboadrdDocumentsStatus')
  private dashboardDocumentsStatus!: any;
  @tblUpdateDocumentModule.Action('getCanselDocumentCompleted')
  private actionCanselDocumentCompleted!: any;

  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Getter('azureUserEmail')
  private azureEmail!: any;
  private selectedStatus: string = '';

  dateSearch: any = '';
  documentType: any = '';
  private searchTerm = '';
  @Watch('tblUpdateDocumentsSearchParams.filter', { deep: true })
  public async onOptionsFilterChanged(newValFilter: string, oldValFilter: string) {
    console.log('------------onOptions FilterChanged------------------------------------------------  ===>>>newVal   ', newValFilter , '  oldVal  ', oldValFilter, "    this.tblUpdateDocumentsSearchParams   ", this.tblUpdateDocumentsSearchParams);
    if (newValFilter == oldValFilter) {
      return;
    }
    this.tblUpdateDocumentsSearchParams.dataOption.page = 1;
    console.log('****************************************************************------CALLED--------action GettblUpdateDocuments----FROM---onOptions FilterChanged---------------------------------------  ===>>>   ');
    this.actionGettblUpdateDocuments({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        documentType: this.documentType,
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
  }

  changeDate(value: any) {
    // prevent strange 2 request with the same value
    if (this.dateSearch == value) {
      return;
    }
    this.dateSearch = value || undefined; // new Date(value).toJSON();
    // this.triggerSearchDataUpdate();
    console.log('****************************************************************------CALLED--------action GettblUpdateDocuments----FROM--- change Date---------------------------------------  ===>>>   ');
    this.actionGettblUpdateDocuments({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        documentType: this.documentType,
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
    this.actionUpdateConditionDashboardDate(value);
    this.actionUpdateDashboardDate(value);
    this.actionUpdateDocumentDashboardDate(value);
  }

  created() {
    console.log(
      '-----tbl UpdateDocument-------------------------------------------------------  ===>>> this.$route.fullPath  ',
      this.$route.fullPath
    );
    if (this.$route.fullPath.includes('/tasks/werbung')) {
      this.documentType = 'werbung';
    } else {
      this.documentType = '';
    }
    if (this.dashboardDate) {
      this.dateSearch = this.dashboardDate;
    }
    console.log(
      '--created---tbl UpdateDocument-------------------------------------------------------  ===>>> this.dashboardDocumentsStatus  ',
      this.dashboardDocumentsStatus
    );
    if (this.dashboardDocumentsStatus) {
      this.selectedStatus = this.dashboardDocumentsStatus;
    } else {
      this.selectedStatus = 'all';
    }
  }

  isInitialRequest = true;
  async mounted() {
    console.log('****************************************************************------CALLED-------- get TblUpdateDocuments----FROM--- MOUNTED---------------------------------------  ===>>>   ');
    var promiseAll = [this.getTblUpdateDocuments()];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }
  private async getTblUpdateDocuments() {
    console.log('****************************************************************--------- get TblUpdateDocuments---------------------------------------  ===>>>   ');
    await this.actionGettblUpdateDocuments({
      searchParams: this.tblUpdateDocumentsSearchParams,
      documentSearchData: {
        datum: this.dateSearch,
        documentType: this.documentType,
        contactEmail: this.azureEmail,
        statusDocument: this.selectedStatus,
      },
    });
  }
  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      //{ text: this.$t('number'), value: 'doc_zrnummer' }, // ds, 09.09.2024 -> according to the item AD-164
      //{ text: this.$t('document_name'), value: 'doc_name' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('updated_on'), value: 'doc_changedate', width: '170px'},
      { text: this.$t('doc_name'), value: 'doc_name' },
      { text: this.$t('document_path'), value: 'doc_path' },
      { text: this.$t('document_created_on'), value: 'doc_erstellt_von'},
      //{ text: this.$t('document_type'), value: 'doc_type' }, // ds, 09.09.2024 -> according to the item AD-164
      //{ text: this.$t('document_sector'), value: 'doc_sector' }, // ds, 09.09.2024 -> according to the item AD-164
      { text: this.$t('document_category'), value: 'doc_category' },
      { text: this.$t('documents_list_completed'), value: 'documentDoneTimeCompleted' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_tblUpdateSupplier.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    // this.$router.push({
    //   name: ROUTES.tblUpdateSupplier,
    //   params: {
    //     tblUpdateSupplierId: CONST.emptyGuid,
    //   },
    // });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.tblUpdateDocumentsSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async tblUpdateDocumentsUpdateOptions(newVal: any, oldVal: any) {
    this.tblUpdateDocumentsSearchParams.dataOption = newVal;    
    console.log('****************************************************************------CALLED--------action GettblUpdateDocuments----FROM--- tblUpdateDocuments UpdateOptions---------------------------------------  ===>>>  this.tblUpdateDocumentsSearchParams', this.tblUpdateDocumentsSearchParams, ' newVal  ', newVal, '    oldVal  ',oldVal, ' newVal.filter  ', newVal.filter );
    // `oldVal` is always `undefined`
    if (newVal != oldVal && this.tblUpdateDocumentsIsLoading != undefined && !this.isInitialRequest) this.getTblUpdateDocuments();
  }

  deleteTblUpdateDocument(editedTblUpdateDocument: TblUpdateDocument) {
    return;
    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteTblUpdateDocument(editedTblUpdateDocument.id).then(() => {
            this.getTblUpdateDocuments();
          });
        }
      });
  }

  private async completedDocument(tblUpdateDocument: any) {
    if (tblUpdateDocument.documentDoneTimeCompleted.length == 0) {
      this.actionAddDocumentCompleted({
        id: tblUpdateDocument.id,
        zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
        contactEmail: this.azureEmail,
      }).then((completedInfo: CompletedInfo) => {
        console.log('------------------------------------------------------------  ===>>>   ', DateUtils.formatDateTimeWithLocaleAndTimezone(completedInfo.сompletedDoneTime));
        tblUpdateDocument.documentDoneTimeCompleted = DateUtils.formatDateTimeWithLocaleAndTimezone(completedInfo.сompletedDoneTime);
        tblUpdateDocument.documentUserNameCompleted = completedInfo.сompletedUserName;
      });
    }
    else{
      this.$confirm
        .open(`${this.$t('completed_dialog_title')}`, `${this.$t('completed_dialog_title_text')}`, {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        })
        .then(async (response: any) => {
          if (response) {
            this.actionCanselDocumentCompleted({
              id: tblUpdateDocument.id,
              zrMaster: this.contactForLoggedInUser ? this.contactForLoggedInUser?.zrMaster : null,
              contactEmail: this.azureEmail,
            }).then((completedInfo: CompletedInfo) => {
              tblUpdateDocument.documentDoneTimeCompleted = '';
              tblUpdateDocument.documentUserNameCompleted = '';
            });
          }
        });
    }
  }

  get title() {
    if (this.$route.fullPath.includes('/tasks/werbung')) {
      return this.$t('titleWerbung');
    } else {
      return this.$t('title');
    }
  }

  changeSelectStatus() {
    console.log(
      '------------------------------change change Select Status----------------------------------------------------------------  ===>>>   ',
      this.selectedStatus
    );
    console.log('****************************************************************------CALLED--------action GettblUpdateDocuments----FROM--- change SelectStatus---------------------------------------  ===>>>   ');
    this.getTblUpdateDocuments();
  }

  get labelTitel() {
    console.log(
      '-------------------labelTitellabelTitellabelTitellabelTitellabelTitellabelTitellabelTitel-----------------------------------------  ===>>>  this.$route.fullPath ',
      this.$route.fullPath
    );
    if (this.$route.fullPath.includes('/suppliers')) {
      return this.$t('supplier_status');
    } else {
      return this.$t('employee_status');
    }
  }

  closeDocuments() {
    console.log('-------------------open Conditios*********************  ===>>>   ');
    this.tblUpdateDocumentsSearchParams.filter = '';
    this.$router.push({
      name: ROUTES.tasks,
    });
  }

  openDocuments(item: TblUpdateDocument) {
    let url: any =
      'https://einrichtungspartnerringvme.sharepoint.com/' +
      item.doc_path_full;
      console.log('------=============open Documents================================------------------------------------------------------  ===>>> url  ', url,
        '  item  ', item.doc_path
      );
    window.open(item.doc_path_full, '_blank');
  }

  private changeSearch(searchTerm: string) {
    if (searchTerm.length < 2) return;
    console.log('-------------------------change Search Document-----------------------------------  ===>>>searchTerm   ', searchTerm);
    this.searchDocuments(searchTerm);
  }
  private changeSearchInput(value: string) {
    console.log('-------------------------change Search Input-----------------------------------  ===>>>searchTerm   ', value);
    if (value == null || value.length == 0) {
      this.resetSearch();
    }
  }
  private resetSearch() {
    const emptyString = '';
    this.searchDocuments(emptyString);
  }

  private async searchDocuments(filterValue: string) {
    this.tblUpdateDocumentsSearchParams.filter = filterValue; // `this.get ContactsSearch` will be called via `@Watch`
    // if (!this.contactsIsLoading) this.get ContactsSearch();
  }

  get titleSearch() {
    if (this.$route.fullPath.includes('/tasks/werbung')) {
      return this.$t('search_Werbung');
    } else {
      return this.$t('search_Document');
    }
  }

  activeState = [
    {
      text: this.$i18n.t(`select_all`), //'Alle',
      value: 'all',
    },
    {
      text: this.$i18n.t(`select_offen`), //'Offen',
      value: 'open',
    },
    {
      text: this.$i18n.t(`select_erledigt`), //'Erledigt',
      value: 'done',
    },
  ];
}
