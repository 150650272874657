import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import gspModule, { GspModule } from '@/shared/model/gspModule';
// import { AUTHORITIES } from '@/shared/store/modules/auth';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';
import { OdataItems } from '@/shared/model/OdataItems';

const name = 'gspModule-edit-dialog';
const logger = new Logger(name);
const gspRoleModule = namespace('gspRole');
const gspModuleManagement = namespace('gspModule');
const gspServiceModule = namespace('gspService');

@Component({
  name: name,
  components: {
    D4yEdit,
  },
})
export default class GspModuleEditDialog extends Vue {
  @gspModuleManagement.Action('getGspModule') private actionGetGspModule!: any;
  @gspModuleManagement.Action('updateGspModule')
  private actionUpdateGspModule!: any;
  @gspModuleManagement.Getter('getGspModuleRoles')
  private roles!: any;

  @gspServiceModule.Getter('getGspServices')
  private gspServices!: OdataItems<any>;
  @gspServiceModule.Action('updateServiceCommonText')
  private updateServiceCommonText!: any;

  @gspRoleModule.Getter('getGspRoleContactTypes')
  private gspRoleContactTypes!: any;

  @Prop()
  moduleId: any;

  selectedContactTypes = [];
  roleContactTypes = [];

  get contactTypes() {
     let result = this.selectedContactTypes;
    return result;
  }


  get services() {
    return this.gspServices.items;
  }

  async mounted() {
    this.roleContactTypes = this.gspRoleContactTypes;
    const gspModuleId = this.moduleId; //this.$route.params['gspModuleId'];
    if (gspModuleId != 0) {
      this.getGspModule();
    } else {
      this.gspModule = gspModule.parse({});
    }
  }

  fillCommonTextIfPresent(val:any) {
    const service = this.services.find(x=> x.id == val)
    if (service && service.short_common_text) {
      this.gspModule.short_common_text = service.short_common_text;
    }
  }

  getGspModule() {
    const gspModuleId = this.moduleId; //this.$route.params['gspModuleId'];
    this.actionGetGspModule(gspModuleId).then((response: GspModule) => {
      this.gspModule = Object.assign({}, response);
      this.selectedContactTypes = this.gspModule.contact_types?.map((x:any) => x.id);

      this.titleInfo = this.gspModule.name;
      this.gspModule.short_common_text = response.service?.short_common_text;
    });
  }
  private gspModule: GspModule = gspModule.parse({});

  @Ref('observer-add-edit-gspModule-form') private observerForm!: any;

  activeTab = 0;
  isLoading = false;
  titleInfo = 'empty';

  get isEmptyGspModule() {
    return this.gspModule.id == 0;
  }

  get image() {
    return {};
  }

  onChangeContactType(value: any) {
    // console.log('onChangeContactType value :>> ', value);
    this.selectedContactTypes = value;
    this.gspModule.contactTypeIds = value;
  }

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    await this.actionUpdateGspModule(this.gspModule).then((resp: GspModule) => {
      this.isLoading = false;

      const payload = {
        id: this.gspModule.serviceId,
        short_common_text: this.gspModule.short_common_text
      }
      this.updateServiceCommonText(payload);
      // this.getGspModule();
    });
  }

  onClickedClose() {
    // this.$router.push({
    //   name: ROUTES.gspModules,
    // });
    this.$emit('close-dialog');
  }
}
