import { ROUTES } from '@/router/routesEnum';
import gspModule, { GspModule } from '@/shared/model/gspModule';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import { CONST } from '@/shared/utils/Constants';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
// import { DefaultViewHelper } from '../ViewHelper';
import GeneralUtils from '@/shared/utils/generalUtils';
import D4yTable from '@/components/d4y-table/d4y-table.vue';

const name = 'gspModules-view';
const gspModuleModule = namespace('gspModule');
const gspRoleModule = namespace('gspRole');

@Component({ name: name, components: { D4yTable } })
export default class GspModulesComponent extends Vue {
  @gspModuleModule.Action('getGspModules') private actionGetgspModules!: any;
  @gspModuleModule.Getter('getGspModules')
  private gspModules!: OdataItems<GspModule>;
  @gspModuleModule.Getter('getGspModulesSearchParams')
  private gspModulesSearchParams!: SearchParams;
  @gspModuleModule.Getter('getGspModulesIsLoading')
  private gspModulesIsLoading!: boolean;
  @gspModuleModule.Action('deleteGspModule')
  private actionDeleteGspModule!: any;

  @gspRoleModule.Action('getGspRoleContactTypes') private actionGetGspRoleContactTypes!: any;
  @gspRoleModule.Getter('getGspRoleContactTypes')
  private gspRoleContactTypes!: any;

  isInitialRequest = true;
  async mounted() {
    var promiseAll = [this.getGspModules(),this.getGspRoleContactTypes()];
    await Promise.all(promiseAll);
    this.isInitialRequest = false;
  }
  private async getGspModules() {
    await this.actionGetgspModules({ searchParams: this.gspModulesSearchParams });
  }

  private async getGspRoleContactTypes() {
    // (AD-176) if no data in getter then call the action
    if (!this.gspRoleContactTypes) {
      await this.actionGetGspRoleContactTypes();
    }
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('service'), value: 'service.name' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get image() {
    return {};
    return {
      src: 'img_gspModule.png',
      header: this.$t('title'),
      width: 360,
      height: 200,
    };
  }

  onAdd() {
    this.add();
  }

  add() {
    this.$emit("add-edit", {type: 'module', id: 0})
    // this.$router.push({
    //   name: ROUTES.gspModule,
    //   params: {
    //     gspModuleId: CONST.emptyGuid,
    //   },
    // });
  }

  editGspModule(editGspModule: GspModule) {
    this.$emit("add-edit", {type: 'module', id: editGspModule.id})
    // this.$router.push({
    //   name: ROUTES.gspModule,
    //   params: { gspModuleId: editGspModule.id },
    // });
  }

  deleteGspModule(editedGspModule: GspModule) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text')}`,
        {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteGspModule(editedGspModule.id).then(() => {
            this.getGspModules();
          });
        }
      });
  }

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get getFilterFields() {
    return this.gspModulesSearchParams.orClauseFieldsIds
      .map(x => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }

  private async gspModulesUpdateOptions(newVal: any, oldVal: any) {
    this.gspModulesSearchParams.dataOption = newVal;
    this.gspModulesSearchParams.filter = newVal.filter;
    // `oldVal` is always `undefined`
    if (newVal != oldVal && this.gspModulesIsLoading != undefined && !this.isInitialRequest) this.getGspModules();
  }
}
