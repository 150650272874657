import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('news-tooltip');


@Component({
  components: {  },
})
export default class NewsTooltip extends Vue {
  @Prop({ default: () => {} })
  private tooltipInfo!: any;
  private DateUtils = DateUtils;  
}
